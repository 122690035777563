.filterDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 80%;
    width: 100%;
    z-index: 200;
    transition: transform 0.3s ease-out;
    transform: translateY(200%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* overflow-x: auto; */
}

.filterDrawer.open {
    transform: translateY(0%);
}

.close {
    margin-right: 2%;
    padding: 1% 1.6% 1.5% 1.6%;
    /* border: 1px solid rgb(250, 103, 103); */
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
}

.closeCircle {
    margin-top: 3%;
}

.filterDrawer_Heading {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 5%;
    color: rgb(120, 120, 120);
}



.reviewStarContainer_1 {
    display: inline-block;
    border-radius: 3px;
    /* border: 1px solid rgb(250, 103, 103); */
    /* background-color: rgb(255, 240, 240); */
    border: 0.1px solid rgb(255, 217, 217);
    padding: 2% 5% 2% 5%;
    margin-bottom: 2%;
}

.reviewStarContainer_2 {
    display: inline-block;
    border-radius: 3px;
    /* background-color: rgb(255, 240, 240); */
    /* border: 1px solid rgb(250, 103, 103); */
    border: 0.1px solid rgb(255, 217, 217);
    padding: 2% 5% 2% 5%;
    margin-bottom: 2%;
}

.reviewStarContainer_3 {
    display: inline-block;
    border-radius: 3px;
    /* background-color: rgb(255, 240, 240); */
    /* border: 1px solid rgb(250, 103, 103); */
    border: 0.1px solid rgb(255, 217, 217);
    padding: 2% 5% 2% 5%;
    margin-bottom: 2%;
}

.reviewStarContainer_4 {
    display: inline-block;
    border-radius: 3px;
    /* background-color: rgb(255, 240, 240); */
    border: 0.1px solid rgb(255, 217, 217);
    padding: 2% 5% 2% 5%;
    margin-bottom: 2%;
}

.filterDrawer_CategoryContainer {
    margin-top: 5%;
}

.filter_sidebar {
    background-color: rgb(255, 255, 255);
    background-color: rgb(255, 237, 237);
    border-right: 1px solid rgb(255, 237, 237);
    height: 100vh;
}

.filter_sidebar_item {
    margin-top: 2%;
    margin-bottom: 5%;
    color: black;
    padding: 4% 3% 4% 8%;
    background-color: rgb(255, 237, 237);
    text-decoration: none;
}

.filter_sidebar_item_clear {
    margin-top: 2%;
    margin-bottom: 5%;
    color: black;
    padding: 4% 3% 4% 8%;
    background-color: rgb(255, 237, 237);
    text-decoration: none;
}


.filter_sidebar_item_selected {
    color: rgb(250, 103, 103);
    /*background-color: rgb(255, 255, 255); */
}

.filter_sidebar_item_selected_reset {
    color: black;
}

.all_filter_container {
    /* border-top: 1px solid rgb(54, 54, 54); */
    border-top: 2px solid rgb(255, 206, 206);
}

.common_filterElement_Container {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid rgb(255, 217, 217);
    padding: 2% 5% 2% 5%;
    margin-bottom: 6%;
}