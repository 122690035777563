.cartRoute {
    /* overflow-y: auto; */
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.CartCheckoutButton {
    width: 100%;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: rgb(250, 103, 103);
}

.myCart {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.smallNavBar_container {
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}

.myCartContainer img {
    margin-bottom: 0.8%;
}

.myCartControls {
    /* border-top: 1px solid rgb(90, 90, 90); */
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding-top: 4%;
    margin-bottom: 7%;

}

.MyCartItemsContainer {
    /* border-top: 1px solid rgb(250, 103, 103); */
}

.myCartControls_subtotal {
    margin-top: 2%;
}

.myCartControls_subtotal span {
    /* margin-left: 3.5px; */
    display: block;
}

.myCartControls_CartCheckoutButton {
    margin-top: 3%;
    background-color: white;
}

.cartIsEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.ProductPage_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 58%;
}

.ProductPage_NavBar_BackAndTitle {
    display: inline-block;
}

.myCartControls_CartCheckoutButton_disclaimer_cont {
    margin-top: 0.5%;
}