.user_dp {
    height: 80px;
}

.user_container {
    background-color: rgb(255, 255, 255);
}

.userNav_Container {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.user_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 49%;
}

.userNav_Text {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.user_dp {
    border-radius: 50%;
}

.user_ControlsAndNavigation_box {
    margin-top: 5%;
}

.user_control_container_box {
    color: black;
    padding: 3% 2% 3% 3%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 2px solid rgb(255, 237, 237);
    text-decoration: none;
}

.user_control_container_box_text {
    padding-left: 10px;
    color: #000000;
    font-weight: 600;
    font-size: 15px;
}

.user_control_container_box_img {
    padding-bottom: 1%;
    height: 21px;
}

.user_MoreControls {
    margin-top: 6%;
    border-top: 4px solid rgb(214, 214, 214);
}

.user_MoreControls_box {
    color: black;
    padding: 3% 2% 3% 2%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    /* border: 1px solid rgb(255, 237, 237); */
    text-decoration: none;
}

.user_MoreControls_box_category {
    padding-left: 4.6%;
    color: rgb(250, 103, 103);
    /* border-top: 1px solid rgb(163, 163, 163); */
    padding-top: 3%;
    margin-top: 5%;
    padding-bottom: 3%;
}

.user_MoreControls_box_text {
    padding-left: 13px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 15px;
}

.user_MoreControls_box_img {
    padding-bottom: 1%;
    height: 22px;
}

.user_dp_and_name {
    margin-bottom: 5%;
}

.greaterThanSideDrawerIcon {
    height: 18px;
}