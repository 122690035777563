.orderHistoryContainer {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.orderHistory_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 45%;
}

.orderHistoryText {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.orderHistoryItemsContainer {
    /* border-top: 1px solid rgb(250, 103, 103); */

}

.orderHistoryEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}