.orderHistoryItem {
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 216, 216);
    padding: 3%;
    margin: 3%;
    border-radius: 9px;
}

.orderHistoryItem:last-child {
    margin-bottom: 0%;
}

.orderHistoryItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-left: 1px solid rgb(250, 103, 103);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.orderHistoryItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-right: 1px solid rgb(250, 103, 103);
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.orderHistoryItemControls_Quantity {
    background-color: rgb(255, 231, 231);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    padding: 5px 12px 5px 12px;
}

.soldby_orderHistoryItemInfo_ProductTitle {
    color: rgb(250, 103, 103);
}

.orderHistoryItemInfo_status_InTransit {
    color: green;
}

.orderHistoryItemInfo_status_Delivered {
    color: rgb(250, 103, 103);
}

.orderHistoryItemInfo_status_Canceled {
    color: red;
}

.orderHistoryItemControls {
    margin-left: 2%;
}

.orderHistoryItemControls_button1 {
    /* background-color: rgba(255, 0, 0, 0.409); */
    background-color: rgb(255, 255, 255);
    /* border: none; */
    /* border: 1px solid rgb(43, 43, 43); */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: rgb(0, 0, 0);
    margin-left: 3%;
}

.orderHistoryItemControls_button2 {
    /* background-color: rgba(255, 239, 147, 0.566); */
    background-color: rgb(255, 255, 255);
    /* border: none; */
    /* border: 1px solid rgb(43, 43, 43); */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-left: 3%;
}

.orderHistoryItemControls {
    margin-top: 2%;
}


/*  */

.orderHistoryItemInfo_SoldBy {
    font-size: 15.5px;
    color: rgb(29, 29, 69);
}

.orderHistoryItemInfo_OrderedOn {
    font-size: 13.5px;
    font-weight: 600;
    color: gray;
}

.orderHistoryItemInfo_ImagesOfProducts {
    margin-top: 3%;
    margin-bottom: 3.2%;
}

.orderHistoryItemInfo_ImagesOfProducts img {
    height: 33px;
    width: 33px;
    margin-right: 2%;
    border-radius: 3px;
    border: 1px solid rgb(255, 216, 216);
}

.orderHistoryItemInfo_ImagesOfProductsEnd {
    display: inline-block;
    height: 33px;
    width: 33px;
    background-color: rgb(240, 239, 242);
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
}

.orderHistoryItemInfo_PandOpHeading {
    font-size: 13.5px;
    color: gray;
}

.orderHistoryItemInfo_PandOp {
    font-size: 15px;
    font-weight: 600;
}