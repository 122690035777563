.aboutUsContainer {
    margin-top: 3.5%;
}

.aboutUs_paragraph {
    color: rgb(61, 61, 61);
    font-size: 15px;
    margin-bottom: 10%;
}

.aboutUs_founder {
    margin-bottom: 5%;
    padding-left: 5%;
    padding-top: 1%;
    padding-right: 5%;
}

.aboutUs_founder_img {
    height: 320px;
    border-radius: 15px;
}

.aboutUs_founder_name {
    margin-top: 3.6%;
}

.founderSocialHandle img {
    height: 25px;
}

.founderSocialHandle {

    padding-right: 7px;
    display: inline;
}

.aboutUsContainer_aboutUs {
    font-size: 22px;
}

.aboutUs_founder_founder {
    color: rgb(120, 120, 120);
    font-size: 18px;
    margin-top: -3px;
    margin-bottom: 6px;
    padding-left: 1px;
}