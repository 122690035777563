.addressSelectorDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 80%;
    width: 100%;
    z-index: 200;
    transition: transform 0.3s ease;
    transform: translateY(300%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow-y: auto;
}

.addressSelectorDrawer.open {
    transform: translateY(0%);
}

.selectAddress_allAddressItemContainer {
    margin-top: 4%;
}