.myAccountDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 70%;
    width: 100%;
    z-index: 200;
    transition: transform 0.3s ease-out;
    transform: translateY(200%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: auto;
}

.myAccountDrawer.open {
    transform: translateY(0%);
}

.myAccountDrawer_LinksContainer {
    padding-top: 6%;
}

.myAccountDrawer_Links {
    margin-bottom: 0.7%;
    font-size: 15.6px;
    padding: 3% 5% 3% 5%;
    display: block;
    text-decoration: none;
    font-weight: 600;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    /* border: 1px solid rgb(255, 237, 237); */
}

.myAccountDrawer_Links:hover {
    color: rgb(0, 0, 0);
}

.myAccountDrawer_Title {
    padding-bottom: 2.5%;
}

.myAccountDrawer_Title_Text {
    color: rgb(250, 103, 103);
}

.myAccountDrawer_More {
    margin-top: 7%;
}