.root {}

.adCarousel {
    max-height: 260px;
    display: flex;
    overflow-x: auto;
}

.bg-swoopkart {
    background-color: rgb(255, 255, 255);
}

.recomendationsRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.recomendationsRoot::-webkit-scrollbar {
    display: none;
}

.popularRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.popularRoot::-webkit-scrollbar {
    display: none;
}

.bestSellerRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.bestSellerRoot::-webkit-scrollbar {
    display: none;
}

.trendingRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.trendingRoot::-webkit-scrollbar {
    display: none;
}

.productRoot {
    min-width: 42%;
    max-height: 255px;
    text-align: start;
    border-left: 1px solid rgb(255, 212, 212);
    border-right: 1px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

/* .productRoot:first-child {
    border-left: 1px solid rgb(250, 103, 103);
}

.productRoot:last-child {
    border-right: 1px solid rgb(250, 103, 103);
} */

.rootWholeContainer {
    padding-top: 9%;
}

.rootProductImage {
    margin-top: 1%;
    margin-bottom: 7px;
    height: 110px;
    width: 110px;
    border-radius: 7px;
    border: 2px solid rgb(240, 240, 240);
    padding: 2%;
}

.rootProductImage_cont {
    /* display: block; */
}

.rootProductTitle {
    margin-right: 5%;
    margin-top: 3.5%;
    margin-left: 3%;
    display: block;
    font-size: 15px;
}

.rootProductPrice {
    margin-left: 3%;
}

.rootProductPrice span {
    margin-bottom: 2%;
    display: block;
}

.productRootInner {
    padding: 8% 3% 8% 3%;
}

.rootMerchantBannerImg {
    height: 90px;
}

.rootMerchantBannerText {
    height: 90px;
    background-color: rgb(109, 244, 247);
}

.rootMerchantBannerText span {
    padding-left: 2%;
    display: block;
}

.rootUserBannerText {
    height: 90px;
    background-color: rgb(1, 194, 255);
}

.rootUserBannerText span {
    padding-left: 4%;
    display: block;
}

.rootUserBannerImg {
    height: 90px;
}

.margin-bottom-4p {
    margin-bottom: 0%;
}

.categoriesRoot_category {
    min-width: 22%;
    max-height: 185px;
    text-align: start;
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

.categoriesRoot_category img {
    display: block;
    padding: 3%;
    height: 46px;
}

.___text strong {
    font-size: 13px;
}

.categoriesRoot_container {
    max-height: 205px;
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 5% 0%;
}

.categoriesRoot_container::-webkit-scrollbar {
    display: none;
}