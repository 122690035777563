.individualProductOrdered {}

.individualProductOrdered_ItemImage img {
    border-radius: 5px;
    height: 130px;
}

.individualProductOrdered_Info {
    margin: 4%;
}

.individualProductOrdered_ImgAndInfoContainer {
    padding: 1% 0px 4% 1%;
}