.ChangeAvatar_container {}

.ChangeAvatar_avatars {
    border-top: 4px solid rgb(223, 223, 223);
    margin-top: 5.5%;
}

.change_avatar_item img {
    height: 90px;
    border-radius: 50%;
}

.change_avatar_item {
    padding: 4%;
    border-radius: 15px;
}

.change_avatar_item:hover {
    box-sizing: content-box;
    border: 2px solid rgba(250, 103, 103, 0.5);
}

.change_avatar_item img:hover {
    height: 88px;
    border-radius: 50%;
}

.ChangeAvatar_avatars_male_female {
    margin-top: 10%;
}