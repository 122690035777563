.savedForLaterItem {
    margin: 3%;
    padding: 3%;
    border-radius: 9px;
    border: 2px solid rgb(255, 216, 216);
}


.savedForLaterProduct {
    padding: 1% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
}

.savedForLaterItemImgAndInfoContainer {
    /* margin-left: 2%; */
    /* padding: 3px 5px 3px 7px; */
}

.savedForLaterItemImage img {
    border-radius: 5px;
    height: 80px;
}

.savedForLaterItemInfo {
    /* margin: 4%; */
}

.savedForLaterItemInfo_ProductTitle {
    padding-top: 0.5%;
    display: block;
}

.savedForLaterItemInfo_ProductPrice {
    /* padding-bottom: 4%; */
    font-size: 18px;
    display: block;
}

.savedForLaterItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-left: 1px solid rgb(250, 103, 103);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.savedForLaterItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-right: 1px solid rgb(250, 103, 103);
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.savedForLaterItemControls_Quantity {
    /* background-color: rgb(255, 231, 231); */
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    padding: 5px 12px 5px 12px;
}

.savedForLaterItemControls_button {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: rgb(250, 103, 103);
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
}

.savedForLaterItemControls {
    margin-top: 4%;
    margin-left: 2%;
}

.wishlistItemControlButton {
    border: 2px solid rgb(255, 232, 232);
}