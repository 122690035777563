.ProductPage {
    /* min-height: 100%; */
    /* overflow-y: auto; */
    /* background-color: rgb(255, 255, 255); */
    /* margin-bottom: 4%; */
}

.ProductPage_NavBar {
    border-bottom: 1px solid rgb(90, 90, 90);
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    position: sticky;
    left: 0;
    top: 0;
    padding-top: 3.5%;
    padding-bottom: 3.5%;


}

.ProductPage_NavBar_img {
    margin-left: 5%;
    margin-bottom: 0.5%;
}

.ProductPage_NavBar_img_logo {
    margin-top: 0.1%;
    margin-left: 75%;
    margin-bottom: 0.4%;
}

.ProductPage_ImageContainer {
    margin-top: 4%;
}

.ProductPage_ImageContainer img {
    border-radius: 6px;
    height: 320px;
    width: 320px;
}

.ProductPage_Rating {
    margin-top: 4.5%;
}

.ProductPage_TitleAndBrand {
    padding-top: 2.5%;
}

.ProductPage_Pricing {
    padding-top: 4%;
}

.ProductPage_Pricing_MRP {
    text-decoration: line-through;
    color: rgb(73, 73, 73);
}

.ProductPage_Pricing_Discount {
    color: red;
    padding-right: 1%;
}

.ProductPage_Delivery {
    margin-top: 4.5%;
    border-top: 3px solid rgb(176, 176, 176);
}

.ProductPage_Delivery_Container {
    margin-top: 4%;
}

.ProductPage_Controls_AddToCart button {
    /* border: 1px solid rgb(250, 103, 103); */
    margin-top: 4.1%;
    margin-bottom: 3%;
    border: none;
    background-color: rgb(250, 103, 103);
    color: white;
    border-radius: 5px;
    width: 100%;
    height: 45px;
}

.ProductPage_Controls_Stock {
    margin-top: 5%;
    color: green;
}

.IPItemControls_button {
    margin-left: 3.2%;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
}

.ProductPage_VendorDeltils {
    text-decoration: none;
    color: black;
    margin-top: 4%;
}

.ProductPage_VendorDeltils_Link {
    text-decoration: none;
    color: rgb(250, 103, 103);
}

.ProductPage_VendorDeltils_Link:hover,
.ProductPage_VendorDeltils_Link:focus {
    text-decoration: none;
    color: rgb(250, 103, 103);
}

.ProductPage_Description {
    margin-top: 6%;
}

.ProductPage_Controls {
    margin-top: 1%;
}

.relatedProductsContainer {
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.ProductPage_RelatedProducts {
    margin-top: 10.5%;
}

.ProductPage_Reviews {
    margin-top: 10.5%;
}

.reviewsContainer {
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.productReview {
    min-width: 55%;
    max-height: 255px;
    text-align: start;
    border-left: 1px solid rgb(255, 212, 212);
    border-right: 1px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

.productReview_review {
    padding-left: 5%;
    padding-right: 2.5%;
    margin-top: 3%;
    margin-bottom: 4%;
}

.productReview_name {
    margin-top: 4%;
    margin-left: 5%;
}

.IpDropReviewsContainer {
    margin-top: 11%;
}

.reviewStarSpacer {
    width: 8px;
}

.fa-star-unchecked {
    color: rgb(255, 216, 152);

}

.addwrittenreviewtext {
    color: rgb(49, 49, 49);
    padding-left: 3px;
}

.leavearating_rating_text {
    display: inline-block;
}

.leavearating_rating {
    display: inline-block;
    padding: 3%;
    border-radius: 7px;
    border: 2px solid rgb(255, 222, 222);
}

.writeareview_controls {
    color: rgb(250, 103, 103);
}

.container_leavearating_rating {
    padding: 1% 3% 2% 3%;
}

.writeareview_controls:first-child {
    margin-right: 10px;
}

.writeareview_controls_container {
    margin-top: 2.5%;
    margin-right: 1%;
}

.floatingTextarea_writeareview_container {
    margin-top: 3%;
}

.reviewsFormContainer {
    border: 2px solid rgb(219, 219, 219);
    padding: 4% 5% 5% 5%;
    border-radius: 5px;
}

.IPItemControls_button:disabled {
    color: rgb(119, 119, 119);
}