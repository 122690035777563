.search {
    background-color: rgb(255, 255, 255);
}

.container-searchResultProducts {
    background-color: rgb(255, 255, 255);
    margin-bottom: 10%;
}

.results_text_search {
    /* padding-left: 2%; */
    padding-bottom: 2%;
}