.searchBox {
    border-top: 1px solid rgb(249, 216, 216);
    z-index: 1;
    height: 3.8rem;
    width: 100%;
}

.searchBox_img {
    height: 27px;
}

#searchField {
    background-color: rgb(255, 255, 255);
    padding: 2% 45px 2% 4%;
    width: 88%;
    font-size: 18px;
    border: 1.5px solid rgb(249, 216, 216);
    border-radius: 15px;
    font-weight: 400;
}

#searchField:focus {
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(249, 216, 216);
}