.Checkout_container {
    margin-top: 6%;
}

.Checkout_Headings {
    padding-left: 2%;
}

.flexCheckChecked_checkout_tnc_cont {
    margin-left: 1.1%;
}

.checkout_borderedButton {
    margin-left: 4px;
    margin-right: 4px;
    padding: 1.9% 15% 1.9% 15%;
    border-radius: 4px;
    border: 1px solid rgb(250, 103, 103);
    color: rgb(250, 103, 103);
    background-color: white;
    font-size: 14.5px;
}

.checkout_colouredButton {
    margin-left: 4px;
    margin-right: 4px;
    padding: 1.9% 15% 1.9% 15%;
    border: 1px solid rgb(250, 103, 103);
    border-radius: 4px;
    background-color: rgb(250, 103, 103);
    color: white;
    font-size: 14.5px;
}


.address_checkout_radio {
    padding-top: 1.5%;
}

.address_checkout_radio_container {
    padding-left: 6%;
}

.AddressForOrder_AddressItem {
    border-radius: 7px;
    border: 2px solid rgb(237, 237, 237);
    margin-top: 4.5%;
}


.AddressForOrderContainer {
    border-radius: 7px;
    padding: 3%;
    border: 2px solid rgb(255, 237, 237);
    margin-top: 3%;
}

.AddressForOrderContainer_orderedFrom strong {
    color: rgb(250, 103, 103);
}

.AddressForOrderContainer_main {
    margin-bottom: 5%;
}


.Checkout_orderNow_AllItemsWithQtt {
    margin-top: 3%;
}

.Checkout_orderNow_detailsContainer {
    border-radius: 6px;
    padding: 4.2% 5%;
    margin-top: 3%;
    border: 2px solid rgb(237, 237, 237);
}

.Checkout_orderNow_payWith_addCoupon {
    color: rgb(250, 103, 103);
}

.Checkout_orderNow_EstimatedDeliveryDate_Charges {
    margin-top: 4%;
    border-top: 2px solid rgb(237, 237, 237);
    padding-top: 4%;
}

.Checkout_orderNow_TnC {
    padding-top: 3%;
}

.Checkout_orderNow_Order_backButton {
    color: rgb(250, 103, 103);
    font-size: 18px;
    margin-left: -5px;
}

.Checkout_orderNow_onlyorderbefore {
    color: rgb(118, 118, 118);
}