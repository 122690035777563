.signInDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 80%;
    width: 100%;
    z-index: 200;
    transition: transform 0.3s ease;
    transform: translateY(300%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.signInDrawer.open {
    transform: translateY(0%);
}

.optionSet {
    color: rgb(0, 0, 0);
}

.optionReset {
    color: rgb(0, 0, 0);
    color: rgb(250, 103, 103);
}

.headingSignin {
    margin-top: 6%;
}

.options label {
    background-color: rgb(255, 255, 255);
    border: none;

}

.link {
    text-decoration: none;
}

.signInButton {
    margin-top: 9%;
}

.signInButton button {
    width: 100%;
    height: 42px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.forgotPasswordRedirect {
    padding-top: 3px;
}

.signInOtherControls_Control {
    font-size: 14px;
    background-color: rgb(255, 238, 238);
    padding: 2.6% 6% 2.6% 6%;
    color: rgb(0, 0, 0);
    font-weight: 450;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.signInOtherControls_Container {
    margin-top: 7%;
}