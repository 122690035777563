.MyAccount_addNewAddresses {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 8px;
    border: 2px solid rgb(231, 231, 231);
}

.MyAddresses_container {
    margin-top: 6%;
}

.MyAccount_addNewAddresses_img img {
    height: 28px;
}

.MyAccount_addNewAddresses_text {
    /* padding-left: 2%; */
    font-size: 17px;
    color: rgb(0, 0, 0);
}

.MyAccount_addNewAddresses_text_exp {
    font-size: 13px;
    font-weight: 600;
}

.addNewAddresses_form {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5% 5% 6.5% 5%;
    border-radius: 5px;
    border: 2px solid rgb(231, 231, 231);
}

.addNewAddresses_form_controls {
    color: rgb(250, 103, 103);
}

.addNewAddresses_form_controls_cancel {
    margin-right: 2%;
}

.addNewAddresses_form_controls_add {
    margin-left: 2%;
}

.AllExistingAddressesContainer {
    padding-bottom: 6%;
}

.addNewAddresses_form_heading {
    text-align: center;
    margin-bottom: 5%;
}

.loadedDataSeparaterForItems {
    margin-top: 2%;
    border-bottom: 1px solid rgb(250, 103, 103);
    margin-bottom: 4.8%;
}

#addNewAdd_towncity:disabled {
    background-color: white;
}

#addNewAdd_StateAndCountry:disabled {
    background-color: white;
}