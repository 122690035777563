.innerFooter {
    padding: 25px 0px 20px 0px;
    margin: 0px 5px 0px 5px;
}

.outerFooter {
    background-color: rgb(28, 29, 31);
}

.linksClass {
    text-decoration: none;
    display: block;
    color: rgb(255, 255, 255);
    font-weight: 500;
    margin-bottom: 1.5px;
}

.linksClass:hover {
    color: rgb(255, 184, 184);
}

.linksFooter {
    /* color: black; */
}

.socialMedia a {
    padding: 4px;
    margin-left: 18px;
    margin: 0px;
}

/* .logos {
    height: 20%;
} */

.logosFooter img {
    height: 33px;
}

#logo {
    padding-left: 2%;
}

.logosFooter {
    margin-top: 2%;
    margin-bottom: 5%;
}

.logosFooter_one img {
    height: 33px;
}

.logosFooter_one {
    margin-top: 2%;
    margin-bottom: 33px;
}

.copyrightsFooter p {
    text-align: center;
    margin-top: 6%;
    color: rgb(255, 255, 255);
}

.color_footer_category {
    color: rgb(250, 103, 103);
}

.footer_terms_conditions_text {
    color: rgb(255, 255, 255);
}

.footer_SocialHandle img {
    height: 24px;
}

.footer_SocialHandle {
    margin-right: 8px;
    display: inline;
}

.footer_SocialHandle_one img {
    height: 22px;
}

.footer_SocialHandle_one {
    margin-right: 2px;
    display: inline;
}