.sortDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 45%;
    width: 100%;
    z-index: 200;
    transform: translateY(200%);
    transition: transform 0.3s ease;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.sortDrawer.open {
    transform: translateY(0%);
}

.sort {
    padding-left: 8%;
    /*padding-right: 10%; */
    /* padding-bottom: 3%; */
    margin-top: 5%;
    /* margin-bottom: %; */
    /* border-bottom: 1px solid rgb(120, 120, 120); */
}

.sort span {
    color: rgb(120, 120, 120);
}

.rbBlock {
    margin: 4% 5% 5% 5%;
}