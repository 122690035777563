.navBar {
    height: 3.8rem;
    width: 100%;
    z-index: 1;
}

.bars {
    height: 25px;
    /* margin-bottom: 2.2px; */
}

.cart {
    margin-top: 4px;
    height: 24px;
    margin-bottom: 2.2px;
}

.drawerToggleButton {
    margin-top: 1px;
    padding: 0px;
    border: none;
    background-color: rgb(255, 255, 255);
}

.navCart {
    /* margin-right: 12px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* margin-left: 8px; */
}

.navSignIn {
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
    /* margin-left: 33px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* padding: 3px 6px 4px 6px; */
    color: rgb(250, 103, 103);
}

.navBarLogo img {
    /* margin-left: 15%; */
}

.navAccount img {
    height: 30.5px;
    border-radius: 50%;
}

.navAccount {
    /* margin-left: 22px; */
    /* border-radius: 50%; */
    margin-right: 10px;
}

.cart-pill-bg {
    background-color: rgb(22, 22, 22)
}

.class-0padding {
    padding: 0px;
    /* margin-right: -15px; */
}

.border-black {
    border-bottom: 1.1px solid rgb(208, 208, 208);
}