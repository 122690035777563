.ExistingAddressItem {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 2px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.add_address_seller {
    color: rgb(250, 103, 103);
}