.loadingRootProductText {
    background-color: rgb(226, 226, 226);
    height: 20px;
    border-radius: 3px;
}

.loadingProductRootImage {
    border-radius: 8px;
    height: 110px;
    width: 110px;
    background-color: rgb(226, 226, 226);
}

.loadingSearchResultProductText1 {
    background-color: rgb(226, 226, 226);
    height: 20px;
    width: 100%;
    border-radius: 3px;
}

.loadingSearchResultProductText2 {
    background-color: rgb(226, 226, 226);
    height: 20px;
    width: 80%;
    border-radius: 3px;
}

.loadingSearchResultProductText3 {
    background-color: rgb(226, 226, 226);
    height: 20px;
    width: 35%;
    border-radius: 3px;
}

.loadingSearchResultProductText4 {
    background-color: rgb(226, 226, 226);
    height: 20px;
    width: 60%;
    border-radius: 3px;
}

.loadingSearchResultProductText5 {
    background-color: rgb(226, 226, 226);
    height: 20px;
    width: 70%;
    border-radius: 3px;
}

.loadingSearchResultProductImage {
    border-radius: 8px;
    height: 110px;
    width: 110px;
    background-color: rgb(226, 226, 226);
}

.loadingCustomSearchResultProduct:last-child {
    border-bottom: 1px solid rgb(250, 103, 103);
}