.savedForLaterContainer {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.savedForLater_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 42%;
}

.mySavedForLater {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.SavedForLaterItemsContainer {
    padding-bottom: 0%;
}

.SavedForLaterEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.innerSavedForLaterIsEmpty {}