.sideDrawer {
    overflow-y: auto;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    left: 0;
    top: 0;
    width: 73%;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease;

}

.sideDrawer.open {
    transform: translateX(0%);
}

.accountHolderIconSideDrawer {
    margin-top: 1.5px;
    height: 35px;
}

.headerSideDrawer {
    margin-top: 5%;
    margin-left: 6%;
    margin-right: 6%;
}

.headerSideDrawer h5 {
    padding-top: 3%;
}

.linkSideDrawer {
    color: black;
    padding: 4% 3% 4% 3%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    /* border: 1px solid rgb(255, 237, 237); */
    text-decoration: none;
    font-size: 15.2px;
    font-weight: 600;
}

.categorySideDrawer {
    padding-left: 4.6%;
    /* border-top: 1px solid rgb(163, 163, 163); */
    padding-top: 3%;
    margin-top: 5%;
    padding-bottom: 3%;
}

.sidebarLinkActive {
    color: rgb(54, 54, 54);
    background-color: rgb(255, 237, 237);
}

.sidebarLinkNotActive {
    color: rgb(54, 54, 54);
    background-color: rgb(248, 249, 250);
}

.linkSideDrawer span {}

.linkSideDrawer:hover {
    color: black;
}

.categorySideDrawer span {
    color: rgb(250, 103, 103);
}

.categoryContainerSideDrawer {
    margin-top: 4%;
    border-top: 3px solid rgb(255, 233, 233);
}

.sidedrawer_footer {
    margin-top: 11%;
    margin-bottom: 6%;
}

.sidedrawer_footer_text {
    color: grey;
}

.headerSideDrawer_username_color {
    color: rgb(250, 103, 103);
}

.accountHolderIconSideDrawer {
    border-radius: 50%;
}



.headerSideDrawer_notlogged {
    margin-top: 3%;
    margin-left: 6%;
    margin-right: 6%;
}