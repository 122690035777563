.cartItem {
    margin: 3%;
    padding: 3%;
    border-radius: 9px;
    border: 2px solid rgb(255, 216, 216);
}


.cartResultProduct {
    padding: 1% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
}

.cartItemImgAndInfoContainer {}

.cartItemImage img {
    border-radius: 5px;
    height: 80px;
}

.cartItemInfo {
    /* margin: 4%; */
    margin: 2% 0% 2% 1%;
}

.cartItemInfo_ProductTitle {
    padding-top: 0.5%;
    display: block;
}

.cartItemInfo_ProductPrice {
    padding-bottom: 4%;
    display: block;
}

.cartItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border: none;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    width: 26px;
    color: black;
    /* width: 25px; */
}


.cartItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border: none;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    width: 26px;
    color: black;
    /* width: 25px; */
}

.cartItemControls_Quantity {
    background-color: rgb(255, 231, 231);
    border: none;
    text-align: center;
    width: 40px;
    padding: 5px 12px 5px 12px;
}

.cartItemControls_button {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
}

.cartItemControls {
    margin-left: 3px;
    margin-top: 2.8%;
}

.cartItemControls_qtt {
    margin-left: 11px;
    margin-right: 5px;
    border-radius: 5px;
}

.cartItemControls_image {
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 3px 12px 3px 12px;
    color: black;
    margin-left: 10px;
    /* margin-right: px; */
    font-size: 13px;
}