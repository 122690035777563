.searchResultProduct {
    padding: 3% 0% 3% 0%;
    border-top: 2px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
}

.searchResultProduct:last-child {
    border-bottom: 2px solid rgb(255, 212, 212);
}

/* .searchResultProduct:nth-child(1) {
    border-top: 2px solid rgb(250, 103, 103);
} */

.productImage {
    height: 145px;
    width: 145px;
    border-radius: 6px;
    border: 2px solid rgb(240, 240, 240);
    padding: 5%;
}

/* background-color: rgb(249, 216, 216); */
.productTitle {
    color: black;
    display: block;
}

.productPrice {
    color: black;
    display: block;
}

.productBrand {
    display: block;
    color: rgb(82, 82, 82);
    font-size: 14px;
}

.productRating {
    display: block;
}

.productDetails {

    /* padding-left: 0px; */
}

.checked {
    color: rgb(250, 103, 103);
}

.noOfRatings {
    font-size: 14px;
    color: rgb(82, 82, 82);

}

.searchResultProduct_discount {
    color: rgb(82, 82, 82);
}