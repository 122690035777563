.pageNotFoundContainer {
    margin-top: 10%;
}

.shopNotFoundContainer {
    margin-top: 5%;
    margin-bottom: 5%;
}

.pageNotFoundContainer_text {
    margin-top: 10px;
    text-align: center;
    color: dimgray;
}

.pageNotFoundContainer_error {
    color: dimgray;
}

.class_404 {
    color: rgb(66, 66, 66);
    margin-top: -5px;
    font-size: 65px;
    margin-bottom: -16px;
}