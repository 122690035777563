.contactUs {}

.contactSupportBox {
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    background-color: rgb(255, 255, 255);
}

.contactSupport_heading {
    margin-top: 7.5%;
    text-align: center;
}

.contactSupport_Box {
    margin: 8% 4% 4% 4%;
}

.contactSupport_button {
    /* padding: 1%; */
    margin-top: 5%;
    margin-bottom: 8%;
}

.contactSupport_button button {
    color: rgb(255, 255, 255);
    padding: 2.5% 8% 2.5% 8%;
    border-radius: 5px;
    background-color: rgb(250, 103, 103);
    border: none;
    /* border: 1px solid rgb(250, 103, 103); */
    width: 100%;
    margin: 0% 4% 4% 4%;
}