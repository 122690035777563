.forgotPasswordRoute {
    background-color: rgb(255, 255, 255);
}

.forgot-password_main {
    background-color: rgb(255, 255, 255);
}

.forgotPasswordRoute_container {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}


.forgotPasswordRoute_Text {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.forgotPasswordRoute_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 39.5%;
}

.forgotPasswordRoute_InstructionsContainer {
    margin-top: 5.5%;
    margin-left: 1%;
    margin-right: 1%;
}

.forgotPasswordRoute_InstructionsContainer_text {
    margin-top: 0.5%;
}

.forgotPasswordRoute_InstructionsContainer_box {
    margin-top: 7%;
}

.forgotPasswordRoute_InstructionsContainer_button button {
    padding: 2.5% 12% 2.5% 12%;
    border-radius: 5px;
    border: none;
    background-color: rgb(250, 103, 103);
    color: white;
}

.forgotPasswordRoute_InstructionsContainer_button {
    margin-top: 8%;
}

.forgotPasswordRoute_text_verify {
    margin-left: 5%;
}