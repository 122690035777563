.nameFields label {
    display: block;
    /* width: 95%; */
}

.nameFields {
    margin-top: 5%;
}

.nameFields input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 4%;
}


.nameFields input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.phoneNumberField {
    margin-top: 3.6%;
}

.phoneNumberField input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 3%;
}

.phoneNumberField input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.emailField {
    margin-top: 3.6%;
}


.emailField input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 3%;
}

.emailField input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.fnd {
    margin-right: 2%;
}

.lnd {
    margin-left: 2%;
}

.nextButton {
    margin-top: 12%;
}

.headingSignUp {
    margin-top: 6%;
}

.verificationText {
    margin-top: 2%;
}

.nextButton button {
    width: 100%;
    height: 40px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.otpBox {
    margin-top: 7%;
}

.otpFields {
    display: inline-block;
    height: 2.6rem;
    width: 15%;
    border: none;
    border-bottom: 2px solid black;
    text-align: center;
    background-color: rgb(255, 255, 255);
}

/* .otpFields:hover {
    border-bottom: 2px solid rgb(250, 103, 103);
} */

.otpSpacer {
    margin-left: 4%;
    margin-right: 4%;
    font-size: x-large;
}

.createPass {
    margin-top: 4%;
}

.createPass label {
    color: rgb(0, 0, 0);
    display: block;
}

.createPass input {
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    width: 100%;
    height: 48px;
    padding: 3%;
}


.createPass input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.createPassButton {
    margin-top: 3%;
}

.createPassButton button {
    width: 100%;
    height: 42px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.disclaimerCreateAccount {
    margin-left: 1%;
    margin-top: 8%;
}

.disclaimerCreateAccount span {
    color: rgb(103, 103, 103);
}