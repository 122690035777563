.filter {
    margin-bottom: 4%;
    background-color: rgb(250, 103, 103);
    border: none;
}

.sortButton {
    border-right: 0.5px solid rgb(255, 255, 255);
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 7%;
    padding-right: 7%;
    font-weight: 500;
}

.filterButton {
    border-left: 0.5px solid rgb(255, 255, 255);
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 7%;
    padding-right: 7%;
    font-weight: 500;
}