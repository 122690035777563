.user_dp_and_name_editprofile {
    margin-bottom: 5%;
}

.edit-profile_edit_dp {
    padding-top: 1.4%;
    padding-bottom: 1%;
    color: rgb(250, 103, 103);
}

#floatingInput_signup_Fname_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_signup_Lname_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_signup_phone_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_signup_email_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_signup_gender_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_signup_Fname_edit:enabled {
    background-color: white;
}

#floatingInput_signup_Lname_edit:enabled {
    background-color: white;
}

#floatingInput_signup_phone_edit:enabled {
    background-color: white;
}

#floatingInput_signup_email_edit:enabled {
    background-color: white;
}

#floatingInput_signup_gender_edit:enabled {
    background-color: white;
}

.edit-profile_cancel {
    margin-right: 4%;
}