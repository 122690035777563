.productmanupulation_container {
    margin-top: 6%;
}

.seller_addProduct_button {
    background-color: rgb(250, 103, 103);
    color: white;
    border: none;
    padding: 1.6% 8% 1.6% 8%;
    border-radius: 5px;
    font-size: 15.5px;
    width: 100%;
}

.addProduct_uploadimagewatermark {
    height: 100px;
    display: block;
}

.addProduct_uploadimagewatermark_text {
    color: gray;
}

.seller_addProduct_button:disabled {
    background-color: rgb(200, 200, 200);
}

.seller_addProduct_button:enabled {
    background-color: rgb(250, 103, 103);
}

.addProduct_uploadedimage {
    height: 225px;
    border-radius: 6px;
}

.all_product_item_controls {
    margin-top: 3.5%;
    margin-bottom: 1%;
    /* margin-left: 2%; */
}

.all_product_item_Controls_button {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-right: 2.5%;
    font-size: 13px;
}

.all_product_itemProduct {
    padding: 3% 0% 3% 0%;
    /* margin: %; */
    border-top: 1px solid rgb(57, 57, 57);
    /* border-bottom: 1px solid rgb(57, 57, 57); */
    background-color: rgb(255, 255, 255);
    /* border-radius: 6px; */
}

.all_product_itemProduct:first-child {
    border-top: none;
}

.all_product_itemProduct:last-child {
    border-bottom: 1px solid rgb(57, 57, 57);
}

/* .searchResultProduct:nth-child(1) {
    border-top: 2px solid rgb(250, 103, 103);
} */

.all_product_itemProduct:hover {
    background-color: rgba(250, 103, 103, 0.063);
}

.checkavailability_sellercracc {
    margin-top: 1.3%;
    padding-right: 1.2%;
    color: rgb(250, 103, 103);
    /* margin-bottom: 0.4%; */
}

.nextButtonCustom:disabled {
    color: rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.606);
}

.nextButtonCustom:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.input-group-text-custom {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(250, 103, 103);
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.errorTextBelowInput {
    margin-top: -10px;
    font-size: 14.5px;
    color: red;
}

.serverErrorAboveButton {
    margin-bottom: -20px;
    font-size: 14.5px;
    color: red;
}

.class_BigHeadingAll {
    color: rgb(66, 66, 66);
    font-size: 65px;
}


.waittime_adddelivery_div {
    padding-right: 3.5%;
}

.waittimeunits_adddelivery_div {
    /* padding-left: 10%; */
    height: 100%;
}

.container-deliveryoptionsdisclaimer {
    margin-top: 10%;
    color: rgb(78, 78, 78);
    margin-bottom: -16px;
}

.addDeliveryDomesticDeliveryDataContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.addDeliveryDomesticDeliveryFormContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.addDeliveryDomesticDeliveryFormControls {
    margin-left: 4%;
}

.enableDomesticDeliveryDisclaimer {
    font-size: 13px;
    color: rgb(250, 103, 103);
}

.ExistingCouponsItem {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.delete_coupon_seller {
    color: rgb(250, 103, 103);
}

.ipo_controls {
    margin-bottom: 2%;
}

.ipo_controls_button {
    background-color: rgb(255, 255, 255);
    /* border: none; */
    /* border: 1px solid rgb(43, 43, 43); */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
}

.extra_ipo_controls {
    margin-left: 2%;
}

.noProductsInShop_myrootshop {
    padding-top: 22%;
    padding-bottom: 22%;
}

.noBSProductsInShop_myrootshop {
    padding-top: 22%;
    padding-bottom: 22%;
}

.edit-root_edit_sellerbaner {
    margin-top: 1.2%;
    padding-right: 2.8%;
    color: rgb(250, 103, 103);
}

.selected_MENU {

    border: 1px solid rgb(230, 230, 230);
}

.globe-Image-region-container {
    margin-top: 6%;
}

.container-regiondisclaimer {
    margin-top: 5%;
    color: rgb(78, 78, 78);
    margin-bottom: -16px;
}

.sellerSellingRegion_globalDeliveryContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.sellerSellingRegion_globalDelivery_Label {
    padding-left: 1%;
}

.ExistingAddressItem {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.delete_region_seller {
    color: rgb(250, 103, 103);
}

.seller-root_heading1 {
    text-align: center;
}

.seller-root {
    background-color: white;
}

.seller-root_banner {
    height: 220px;
}

.seller-root_heading1_text {
    padding-top: 4%;
    background-color: rgb(255, 255, 255);
    /* color: rgb(250, 103, 103); */
}

.seller-root_startSelling button {
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    background-color: rgb(250, 103, 103);
    color: white;
    padding: 1.5% 10% 1.5% 10%;
}

.seller-root_tac {
    font-size: 13px;
    padding-top: 2%;
    color: grey;
    margin-bottom: 9%;
}

.whysellonswoop {
    margin-top: 5%;
}

.whysellonswoop_reasonsContainer {
    padding: 6% 7% 6% 7%;
    margin-top: 4%;
    border-radius: 10px;
    /* border: 1px solid rgb(138, 138, 138); */
    border: 1px solid rgb(250, 103, 103);
}

.whysellonswoop_img {}

.whysellonswoop_text {
    color: rgb(84, 84, 84);
}

.seller-root_becomeaseller {
    font-size: 30px;
    color: rgb(250, 103, 103);
}

.seller_root_image {
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-top: 25px; */
    background-color: rgb(250, 103, 103);
}

.seller_updateProduct_cancelbutton {
    background-color: white;
    color: rgb(250, 103, 103);
    border: 1px solid rgb(250, 103, 103);
    padding: 1.6% 5.5% 1.6% 5.5%;
    border-radius: 5px;
    margin-right: 3.5%;
    font-size: 15.5px;
}

.wantAPhysicalQR_main {
    margin-top: 9%;
    margin-bottom: 10%;
}

.wantAPhysicalQR_heading {
    font-size: 30px;
    color: rgb(250, 103, 103);
}

.WPQR_buttons_c {
    background-color: rgb(250, 103, 103);
    border-radius: 5px;
    border: none;
    padding: 2.5%;
    width: 100%;
    color: white;
    font-size: 17px;
}

.WPQR_buttons_c:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.WPQR_buttons {
    margin-top: 6.5%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    padding: 2.5%;
    width: 100%;
    color: rgb(250, 103, 103);
    font-size: 17px;
}

.WPQR_buttons:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}


.WPQR_buttons_black {
    margin-top: 6.5%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(0, 0, 0);
    padding: 2.5%;
    width: 100%;
    color: rgb(0, 0, 0);
    font-size: 16px;
}

.WPQR_buttons_black:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 38%);
}


.WPQR_buttons_controls {
    margin-top: 13%;
}

.WPQR_tagline {
    font-size: 16px;
    padding-top: 3%;
}

.WPQR_PhysicalQR {
    margin-top: 9%;
}

.WPQR_tagline_Free {
    margin-top: -3px;
    font-size: 37px;
    color: rgb(250, 103, 103);
}

.WPQR_tagline_moretext {
    margin-top: -5px;
    font-size: 23px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer {
    border: 1px solid rgb(255, 177, 177);
    background-color: white;
    border-radius: 4px;
    padding: 3%;
    padding-top: 5%;
}

.enterShopNameContainer_Controls b {
    font-size: 15px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer_Controls b:first-child {
    font-size: 15px;
    margin: 0px 4% 0px 0px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer_Controls {
    margin: 1% 0px 0% 0px;
}

.innerFooter {
    padding: 20px 0px 20px 0px;
    margin: 0px 5px 0px 5px;
}

.linksClass {
    text-decoration: none;
    display: block;
}

.socialMedia a {
    padding: 4px;
    margin-left: 18px;
    margin: 0px;
}

/* .logos {
    height: 20%;
} */

.logos img {
    height: 25px;
}

#logo {
    padding-left: 2%;
}

.logos {
    margin-top: 5%;
}

.copyrights p {
    text-align: center;
    margin-top: 6%;
    color: white;
}

.sellerLogoNavBar_container {
    z-index: 10;
    padding-top: 6.5%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}



.form-switch .form-check-input:focus {
    background-image: url("../icons/icons8-filled-circle-100(1).png");
}

.form-check-input:focus {
    border-color: rgba(250, 103, 103, 0.8);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 25%);
}

.form-check-input:checked {
    background-color: rgb(250 103 103);
    border-color: rgb(250 103 103);
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("../icons/icons8-filled-circle-100(2).png");
}

.form-control:focus {
    border-color: rgba(250, 103, 103, 0.180);
    box-shadow: 0 0 0 0.215rem rgb(250 103 103 / 18%);
}

.form-select:focus {
    border-color: rgba(250, 103, 103, 0.180);
    box-shadow: 0 0 0 0.215rem rgb(250 103 103 / 18%);
}

.seller_updateProduct_cancelbutton:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.seller_addProduct_button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.footerSignIn {
    z-index: 1;
}