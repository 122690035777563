.shop_page_container {
    /* margin-top: 5%; */
}

.shop_page_sellerbanner img {
    width: 100%;
}

.shop_page_name {
    color: rgb(250, 103, 103);
}

.shop_page_heading {
    color: black;
    /* background-color: ;; */
}

.sellerBestSellingContainer {
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.shop_page_sellerbanner_logo img {
    margin-left: 10%;
    border-radius: 50%;
    height: 70px;
}

.shop_page_sellerbanner_logo {
    padding-top: 2%;
    padding-left: 5%;
}